import { withAuthenticationRequired } from "@auth0/auth0-react";
import type { ComponentType } from "react";

function AuthedElement({ element }: { element: ComponentType }) {
  const loc = window.location;

  const ProtectedElement = withAuthenticationRequired(element, {
    returnTo: loc.pathname + loc.search + loc.hash,
  });

  return <ProtectedElement />;
}

export default AuthedElement;
