import { type AppState, Auth0Provider } from "@auth0/auth0-react";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  children: ReactNode;
};

export default function Auth0ProviderWithHistory({ children }: Props) {
  const domain = import.meta.env.VITE_PUBLIC_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_PUBLIC_AUTH0_CLIENT_ID;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || "/");
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://core-tasks-api.magmutual.com",
        scope: "openid profile email offline_access",
        connection: "CyberArk-SAML",
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      legacySameSiteCookie={false}
    >
      {children}
    </Auth0Provider>
  );
}
